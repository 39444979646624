:root {
  --primary-color: #0c2340;
  --white: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.table-style {
  font-family: inherit;
  border-collapse: collapse;
  width: 100% !important;
}

.table-header {
  background-color: var(--primary-color);
  border: 1px solid #dddddd;
  text-align: left;
  color: var(--white);
  padding: 8px;
  font-weight: 500;
}

.table-data {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table-data:last-child {
  background-color: #fff;
  border: none;
  width: 20px;
}

.table-row:nth-child(even) {
  background-color: #e7e9ec;
}

.input-style {
  padding: 10px;
  width: 100%;
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Utility Class */
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.breadcrumbs-link:hover {
  text-decoration: underline;
}

.breadcrumbs-link-last {
  color: var(--primary-color);
}
.breadcrumbs-link-last:hover {
  text-decoration: none;
}

.primary-header {
  margin: 0;
  /* margin-bottom: 20px; */
  font-family: 'Poppins', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 3rem;
  line-height: 1.2;
}

.login-container {
  min-height: 100vh;
}

.login-form-container {
  width: 70%;
  padding: 20px 30px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.grid-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-art {
  width: 200px;
  margin-bottom: 10px;
}

.answer-option-chip {
  margin-right: 5px;
  margin-bottom: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Mobile Styles */
@media (max-width: 700px) {
  .primary-header {
    font-size: 2rem;
    line-height: 1.2;
    text-align: center;
    width: 100%;
  }

  .login-container {
    min-height: 80vh;
  }

  .login-form-container {
    width: 100%;
    padding: 15px 15px 15px 15px;
  }

  .login-art-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 200px;
  }

  .login-art {
    width: 150px;
    /* display: block; */
  }
}
